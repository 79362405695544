import { Image } from 'react-native';
import { styled } from '../../styled';
export default styled(Image, {
    w: '100%',
    h: '100%',
    borderRadius: '$full',
    position: 'absolute',
}, {
    componentName: 'AvatarImage',
});
