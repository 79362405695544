import { createActionsheet } from '@gluestack-ui/actionsheet';
import { styled } from '../styled';
import { Root, Content, Item, ItemText, DragIndicator, IndicatorWrapper, Backdrop, ScrollView, VirtualizedList, FlatList, SectionList, SectionHeaderText, Icon, } from './styled-components';
export const AccessibleActionsheet = createActionsheet({
    Root,
    Content,
    Item,
    ItemText,
    DragIndicator,
    IndicatorWrapper,
    Backdrop,
    ScrollView,
    VirtualizedList,
    FlatList,
    SectionList,
    SectionHeaderText,
    Icon,
    //@ts-ignore
    AnimatePresence: styled.Component,
});
export const Actionsheet = AccessibleActionsheet;
export const ActionsheetContent = AccessibleActionsheet.Content;
export const ActionsheetItem = AccessibleActionsheet.Item;
export const ActionsheetItemText = AccessibleActionsheet.ItemText;
export const ActionsheetDragIndicator = AccessibleActionsheet.DragIndicator;
export const ActionsheetDragIndicatorWrapper = AccessibleActionsheet.DragIndicatorWrapper;
export const ActionsheetBackdrop = AccessibleActionsheet.Backdrop;
export const ActionsheetScrollView = AccessibleActionsheet.ScrollView;
export const ActionsheetVirtualizedList = AccessibleActionsheet.VirtualizedList;
export const ActionsheetFlatList = AccessibleActionsheet.FlatList;
export const ActionsheetSectionList = AccessibleActionsheet.SectionList;
export const ActionsheetSectionHeaderText = AccessibleActionsheet.SectionHeaderText;
export const ActionsheetIcon = AccessibleActionsheet.Icon;
