import { View } from 'react-native';
import { styled } from '../../styled';
export default styled(View, {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
}, {
    componentName: 'SelectActionsheet',
});
