import { createAlertDialog } from '@gluestack-ui/alert-dialog';
import { Root, Content, CloseButton, Header, Footer, Body, Backdrop, } from './styled-components';
import { styled } from '../styled';
export const AccessibleAlertDialog = createAlertDialog({
    Root,
    Content,
    CloseButton,
    Header,
    Footer,
    Body,
    Backdrop,
    //@ts-ignore
    AnimatePresence: styled.Component,
});
export const AlertDialog = AccessibleAlertDialog;
export const AlertDialogContent = AccessibleAlertDialog.Content;
export const AlertDialogCloseButton = AccessibleAlertDialog.CloseButton;
export const AlertDialogHeader = AccessibleAlertDialog.Header;
export const AlertDialogFooter = AccessibleAlertDialog.Footer;
export const AlertDialogBody = AccessibleAlertDialog.Body;
export const AlertDialogBackdrop = AccessibleAlertDialog.Backdrop;
