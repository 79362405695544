import { styled } from '../../styled';
import { View } from 'react-native';
export default styled(View, {
    flexDirection: 'column',
    defaultProps: {
        space: 'md',
    },
}, {
    componentName: 'VStack',
});
